.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    border-bottom: 1px solid white;
    position: fixed;
    z-index: 1;
    background-color: black;
}

.headerBtnList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85vw;
    list-style: none;

}

.headerBtn {
    color: white;
    list-style: none;
    cursor: pointer;
    font-weight: 500;
}