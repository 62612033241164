.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    min-height: 100vh;
    width: 100vw;
}

.logo-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.logo {
    display: flex;
    align-items: center;
    height: 200px;
    width: 200px;
}

.text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%
}

.headline {
    color: white;
    font-weight: 700;
}

.testimonial-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 30px;
    margin-bottom: 25px
}

.testimonial-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    font-style: italic
}

.required {
    color: red;
    margin-left: 3px;
}


.quote-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: white;
    margin-left: 7px;
}

.slick-prev, .slick-next {
    background-color: black;
    display: none !important;
}

  
  .prev {
    order: -1;
    cursor: pointer ;
    align-self: center;
  }
  
  .next {
    /* padding-left: 5px; */
    order: 1;
    cursor: pointer;
  }

  .slider-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100vw;
}

.slider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
    border: 2px solid white;
    border-radius: 8px;
    background-color: black;
    cursor: pointer;
}

.testimonial-name {
    color:white;
    margin-left: 7px;
    font-weight: 900
}

.testimonial-text {
    color:white;
    margin-left: 7px;
    margin-right: 5px;
    font-style: italic;
}

.slick-dots li button:before {
    color: white !important;
    opacity: 1;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-top: 20px;
}

.about-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 30px;
}

.about-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    font-style: italic;
    width: 80%;
    /* border: 2px solid white; */
    border-radius: 8px;
}

.how-it-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.how-text{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    font-style: italic;
    width: 80%;
    /* border: 2px solid white; */
    padding-left:0;
    gap: 10px;
    
}

.number {
    display: inline;
    width: 100%;
    color: gold;
    font-weight: 700;
    margin-right: 5px;
}

.contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.contact-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 30px;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
    border: 1px solid white;
    background-color: white;
    border-radius: 8px;
    position: relative;
    margin-bottom: 0;
}

.contact-name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 700;
    width: 80%;
}

.contact-email {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    font-weight: 700;
    width: 80%;
}

.contact-message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    font-weight: 700;
    width:80%;
    
}

.user-name {
    border-radius: 8px;
    height: 30px;
    width: 80%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.user-email {
    border-radius: 8px;
    height: 30px;
    width: 80%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.user-message {
    border-radius: 8px;
    height: 100px;
    width: 80%;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.contact-send {
    border-radius: 8px;
    height: 30px;
    width: 100px;
    margin-bottom: 10px;
    cursor: pointer;
}

.user-phone {
    border-radius: 8px;
    height: 30px;
    width: 80%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.gender {
    border-radius: 8px;
    height: 30px;
    width: 80%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.copy-rights {
    color: white;
    margin: 0
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
}
@media screen and (min-width: 600px) {
    .logo {
        height: 300px;
        width: 300px;
    }

    .headline {
        font-size: 18px;
    }

    .testimonial-title {
        font-size: 26px
    }

    .testimonial-subtitle {
        font-size: 18px;
    }

    .about-title {
        font-size: 26px;
    }

    .about-text {
        font-size: 18px;
    }

    .how-text {
        font-size: 18px;
    }

    .contact-title {
        font-size: 26px;
    }

    .loading {
        font-size: 20px;
    }

}

@media screen and (min-width: 730px) {
   

    .slider {
        width: 70%;
    }

    .about {
        width: 85vw;
    }

    .how-it-works {
        width: 85vw;
    }


    .form-container {
        width: 70vw;
    }
}

@media screen and (min-width: 945px) {
    .logo {
        height: 400px;
        width: 400px;
    }

    .headline {
        font-size: 20px;
    }

    .testimonial-title {
        font-size: 30px
    }

    .testimonial-subtitle {
        font-size: 20px;
    }



    .about-title {
        font-size: 30px;
    }

    .about-text {
        font-size: 20px;
    }

    .how-text {
        font-size: 20px;
    }

    .contact-title {
        font-size: 30px;
    }

}


@media screen and (min-width: 1200px) {
    .logo {
        /* height: 450px; */
        width: 500px;
    }

    .headline {
        font-size: 24px;
    }

    .testimonial-title {
        font-size: 34px
    }

    .testimonial-subtitle {
        font-size: 24px;
    }

    .slider {
        width: 50%;
    }

    .testimonial-name {
        font-size: 20px;
    }

    .testimonial-text {
        font-size: 20px;
    }

    .about {
        width: 63vw;
    }

    .about-title {
        font-size: 34px;
    }

    .about-text {
        font-size: 24px;
    }

    .how-it-works {
        width: 63vw;
    }

    .how-text {
        font-size: 24px;
    }

    .contact-title {
        font-size: 34px;
    }

    .form-container {
        width: 50vw
    }
}